import { FC } from "react";
import { Spinner } from "../../components/Spinner/Spinner";

export const LoadingScreen: FC = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <div className="w-96 h-48 rounded-md flex">
                <div className="m-auto text-center">
                  <Spinner size={80} color="light-blue"/>
                  <p className="text-gray-500 text-xl leading-7 font-normal pt-5">Loading your content...</p>
                </div>
            </div>
        </div>
    </div>
);