/* eslint-disable import/no-cycle */
import axios from 'axios';

export const configure = ({
  baseURL,
}: {baseURL:string}) => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
};

export const updateAccessToken = (token: string) => {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
};

export default axios;
