import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from 'formik';
import { TextField } from "../../components/formik/TextField/TextField";
import { FormStatusErrors } from "../../components/formik/FormStatusErrors";
import { Button } from "../../components/Button/Button";
import * as Yup from 'yup';
import { useMutation } from "@tanstack/react-query";
import updateUserById from "../../api-calls/updateUserById";
import { AxiosResponse } from "axios";
import { Spinner } from "../../components/Spinner/Spinner";

interface PasswordFormValues {
    password: string,
    passwordConfirmation: string
}

const validationSchema: Yup.ObjectSchema<PasswordFormValues> = Yup.object({
    password: Yup.string()
      .required("Please enter a password")
      .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9@!#$%^&*]).{8,}$/, "Must contain 8 characters, one uppercase, one lowercase, one number or one special case character"),
    passwordConfirmation: Yup.string().required("Please re-type your password").oneOf([Yup.ref('password')],"Password confirmation doesn't match")
})

type PasswordFormProps = {
    onPasswordChangeSuccess: (data: AxiosResponse) => void,
    userId: number
}

export const PasswordForm: FC<PasswordFormProps> = props => {
    const { onPasswordChangeSuccess, userId } = props
    const updatePasswordMutation = useMutation({mutationFn: (passwordData: object) => updateUserById(userId, passwordData)})
    const onSubmit = useCallback(async(values: PasswordFormValues, formikActions: FormikHelpers<PasswordFormValues>) => {
        const { setStatus, setSubmitting } = formikActions;

        updatePasswordMutation.mutate({activate: true, password: values.password}, {
            onSuccess: onPasswordChangeSuccess,
            onError: (error: any) => {
                setStatus({ errors: [{
                     key: "", 
                     message: error.response.status === 500 ? "Password was not defined, something went wrong." : error.response.data.message 
                    }] });
            },
            // Finally
            onSettled: () => {
                setSubmitting(false)
            }
        })
    }, [])
    return (
        <div className="passwordForm w-full md:w-7/12">
            <Formik<PasswordFormValues>
            initialValues={{ password: '', passwordConfirmation: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            >
                {({
                     handleSubmit,
                     isSubmitting,
                     status
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormStatusErrors status={status} />
                        <div className="grid grid-rows-2 gap-2">
                            <TextField 
                              label="Password" 
                              name="password" 
                              type="password"
                            />
                            <TextField 
                              label="Confirm Password" 
                              name="passwordConfirmation" 
                              type="password"
                            />
                        </div>
                        <div className="mt-8">
                          <span className="block w-full rounded-md shadow-sm">
                            <Button
                                type="submit"
                                disabled={updatePasswordMutation?.isLoading}
                                color="blue"
                                className="ssm:w-1/3 float-right"
                            >
                                Set Password
                                {updatePasswordMutation?.isLoading &&
                                  <Spinner className="ml-1" size={10} color="light-blue" borderSize={2}/>
                                }
                            </Button>
                          </span>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}