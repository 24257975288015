import { FC, ReactNode, useState } from "react";
import ChevronUp from '@heroicons/react/outline/ChevronUpIcon'
import ChevronDown from '@heroicons/react/outline/ChevronDownIcon'

type QuestionSet = {
    question: string,
    answer: ReactNode
};

type QuestionProps = {
    question: QuestionSet,
    index: number
}

const Question: FC<QuestionProps> = ({question, index}) => {
    const [shown, setShown] = useState<boolean>(false)
    return (
    <div className="border-t-2 border-white" key={`question-${index}`}>
        <div className="py-5">
            <span className="text-white font-medium text-lg leading-7">{question.question}</span>
            <button className="border-none text-indigo-200 text-4xl float-right" onClick={() => setShown((prevVal) => !prevVal)}>
                {shown ? <ChevronUp className="w-6 h-6" /> : <ChevronDown className="w-6 h-6"/>}
            </button>
        </div>
        <div className={`text-indigo-200 pb-5${shown ? '' : ' hidden'}`}>
            {question.answer}
        </div>
    </div>)
} 

type FAQProps = {
    questions: QuestionSet[]
}
export const FAQ: FC<FAQProps> = ({questions}) => {
    return (
        <div className="w-full bg-blue-600">
            <div className="mx-auto my-20 lg:px-96 px-10 ">
                <p className="text-white text-4xl font-extrabold leading-10">Frequently Asked Questions</p>
                <div className="py-10">
                    {questions.map((question: QuestionSet, index) => (
                        <Question question={question} index={index}/>
                    ))}
                </div>
            </div>
        </div>
    )
}