import styled, {} from 'styled-components';
import "./Spinner.css";

const defaultSize = 10;
const defaultBorderSize = 10;

const colors = Object.freeze({
  'light-blue': 'hsl(207, 68%, 66%)',
  blue: 'hsl(212, 83%, 28%)',
});

interface SpinnerProps {
  size?: number;
  color?: keyof typeof colors;
  borderSize?: number;
}

export const Spinner = styled.div<SpinnerProps>`
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: ${props => props.size || defaultSize}px;
  width: ${props => props.size || defaultSize}px;
  border: ${props => props.borderSize || defaultBorderSize}px solid ${props => colors[props.color || 'light-blue']};
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: content-box;
`;
