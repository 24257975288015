import React from "react";

export type ImageObject = {
  src: string;
  srcSet: string;
  sizes?: string;
  base64?: string;
  srcWebp?: string;
  srcSetWebp?: string;
};

export type ImageProps = {
  width: number;
  height: number;
  image: ImageObject;
  // src: string;
  alt: string;
  fadeIn?: boolean;
  critical?: boolean;
  title?: string;
  className?: string | object;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  placeholderStyle?: object;
  placeholderClassName?: string | object;
  backgroundColor?: string | boolean;
  itemProp?: string;
  onLoad?: () => void;
  onStartLoad?: (param: { wasCached: boolean }) => void;
  onError?: (event: any) => void;
  crossOrigin?: "" | "anonymous" | "use-credentials" | undefined;
};

// Cache if we've seen an image before so we don't bother with
// lazy-loading & fading in on subsequent mounts.
const imageCache: { [x in string]: boolean } = Object.create({});
const inImageCache = (props: ImageProps) => {
  // Find src
  const src = props.image.src;

  return imageCache[src] || false;
};

const activateCacheForImage = (props: ImageProps) => {
  const src = props.image.src;
  imageCache[src] = true;
};

let io: IntersectionObserver;
const listeners = new WeakMap();

function getIO() {
  if (
    typeof io === `undefined` &&
    typeof window !== `undefined` &&
    window.IntersectionObserver
  ) {
    io = new window.IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (listeners.has(entry.target)) {
            const cb = listeners.get(entry.target);
            // Edge doesn't currently support isIntersecting, so also test for an intersectionRatio > 0
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              io.unobserve(entry.target);
              listeners.delete(entry.target);
              cb();
            }
          }
        });
      },
      { rootMargin: `200px` }
    );
  }
  return io;
}

const listenToIntersections = (el: HTMLElement, cb: () => void) => {
  const observer = getIO();

  if (observer) {
    observer.observe(el);
    listeners.set(el, cb);
  }

  return () => {
    observer.unobserve(el);
    listeners.delete(el);
  };
};

const noscriptImg = (props: ImgProps) => {
  // Check if prop exists before adding each attribute to the string output below to prevent
  // HTML validation issues caused by empty values like width="" and height=""
  const src = props.src ? `src="${props.src}" ` : `src="" `; // required attribute
  const sizes = props.sizes ? `sizes="${props.sizes}" ` : ``;
  const srcSetWebp = props.srcSetWebp
    ? `<source type='image/webp' srcset="${props.srcSetWebp}" ${sizes}/>`
    : ``;
  const srcSet = props.srcSet ? `srcset="${props.srcSet}" ` : ``;
  const title = props.title ? `title="${props.title}" ` : ``;
  const alt = props.alt ? `alt="${props.alt}" ` : `alt="" `; // required attribute
  const width = props.width ? `width="${props.width}" ` : ``;
  const height = props.height ? `height="${props.height}" ` : ``;
  const crossOrigin = props.crossOrigin
    ? `crossorigin="${props.crossOrigin}" `
    : ``;

  return `<picture>${srcSetWebp}<img ${width}${height}${sizes}${srcSet}${src}${alt}${title}${crossOrigin}style="position:absolute;top:0;left:0;opacity:1;width:100%;height:100%;object-fit:cover;object-position:center"/></picture>`;
};

type ImgProps = {
  width: number;
  height: number;
  src: string;
  srcSet?: string;
  onLoad?: () => void;
  onError?: (event: any) => void;
  style?: object;
  srcSetWebp?: string;
  title?: string;
  alt?: string;
  crossOrigin?: "" | "anonymous" | "use-credentials" | undefined;
  sizes?: string;
  itemProp?: string;
};

const Img = React.forwardRef(
  (props: ImgProps, ref: React.Ref<HTMLImageElement>) => {
    const { alt, srcSet, src, style, onLoad, onError, ...otherProps } = props;

    return (
      <img
        alt={alt || ""}
        srcSet={srcSet}
        src={src}
        {...otherProps}
        onLoad={onLoad}
        onError={onError}
        ref={ref}
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
          objectFit: `cover`,
          objectPosition: `center`,
          ...style
        }}
      />
    );
  }
);

type ImageState = {
  isVisible: boolean;
  imgLoaded: boolean;
  imgCached: boolean;
  IOSupported: boolean;
  fadeIn: boolean;
  hasNoScript: boolean;
  seenBefore: boolean;
};

class Image extends React.Component<ImageProps, ImageState> {
  static defaultProps = {
    critical: false,
    fadeIn: true,
    alt: ``
  };
  imageRef: React.RefObject<HTMLImageElement>;
  cleanUpListeners: undefined | (() => void);

  constructor(props: ImageProps) {
    super(props);

    // default settings for browser without Intersection Observer available
    let isVisible = true;
    let imgLoaded = false;
    let imgCached = false;
    let IOSupported = false;
    let fadeIn = props.fadeIn || true;

    // If this image has already been loaded before then we can assume it's
    // already in the browser cache so it's cheap to just show directly.
    const seenBefore = inImageCache(props);

    // browser with Intersection Observer available
    if (
      !seenBefore &&
      typeof window !== `undefined` &&
      window.IntersectionObserver
    ) {
      isVisible = false;
      IOSupported = true;
    }

    // Never render image during SSR
    if (typeof window === `undefined`) {
      isVisible = false;
    }

    // Force render for critical images
    if (props.critical) {
      isVisible = true;
      IOSupported = false;
    }

    const hasNoScript = !(props.critical && !props.fadeIn);

    this.state = {
      isVisible,
      imgLoaded,
      imgCached,
      IOSupported,
      fadeIn,
      hasNoScript,
      seenBefore
    };

    this.imageRef = React.createRef<HTMLImageElement>();
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  componentDidMount() {
    if (this.state.isVisible && typeof this.props.onStartLoad === `function`) {
      this.props.onStartLoad({ wasCached: inImageCache(this.props) });
    }
    if (this.props.critical) {
      const img = this.imageRef.current;
      if (img && img.complete) {
        this.handleImageLoaded();
      }
    }
  }

  componentWillUnmount() {
    if (this.cleanUpListeners) {
      this.cleanUpListeners();
    }
  }

  handleRef(ref: HTMLDivElement) {
    if (this.state.IOSupported && ref) {
      this.cleanUpListeners = listenToIntersections(ref, () => {
        const imageInCache = inImageCache(this.props);
        if (
          !this.state.isVisible &&
          typeof this.props.onStartLoad === `function`
        ) {
          this.props.onStartLoad({ wasCached: imageInCache });
        }

        // imgCache and imgLoaded must update after isVisible,
        // Once isVisible is true, imageRef becomes accessible, which imgCached needs access to.
        // imgLoaded and imgCached are in a 2nd setState call to be changed together,
        // avoiding initiating unnecessary animation frames from style changes.
        this.setState({ isVisible: true }, () =>
          this.setState({
            imgLoaded: imageInCache,
            imgCached: !!this.imageRef!.current!.currentSrc
          })
        );
      });
    }
  }

  handleImageLoaded() {
    activateCacheForImage(this.props);

    this.setState({ imgLoaded: true });
    if (this.state.seenBefore) {
      this.setState({ fadeIn: false });
    }

    if (this.props.onLoad) {
      this.props.onLoad();
    }
  }

  render() {
    const {
      title,
      alt,
      className,
      style = {},
      imgStyle = {},
      placeholderStyle = {},
      placeholderClassName,
      backgroundColor,
      itemProp,
      image,
      width,
      height
    } = this.props;

    const shouldReveal = this.state.imgLoaded || this.state.fadeIn === false;
    const shouldFadeIn = this.state.fadeIn === true && !this.state.imgCached;
    const durationFadeIn = `0.5s`;

    const imageStyle = {
      opacity: shouldReveal ? 1 : 0,
      transition: shouldFadeIn ? `opacity ${durationFadeIn}` : `none`,
      ...imgStyle
    };

    const bgColor =
      typeof backgroundColor === `boolean` ? `lightgray` : backgroundColor;

    const delayHideStyle = {
      transitionDelay: durationFadeIn
    };

    const imagePlaceholderStyle = {
      opacity: this.state.imgLoaded ? 0 : 1,
      ...(shouldFadeIn && delayHideStyle),
      ...imgStyle,
      ...placeholderStyle
    };

    const placeholderImageProps = {
      title,
      alt: !this.state.isVisible ? alt : ``,
      style: imagePlaceholderStyle,
      className: placeholderClassName
    };

    // Assuming always `fixed`:
    const divStyle: React.CSSProperties = {
      position: `relative`,
      overflow: `hidden`,
      display: `inline-block`,
      width: width,
      height: height,
      ...style
    };

    if (style.display === `inherit`) {
      delete divStyle.display;
    }

    return (
      <div
        className={`${className ? className : ``} onboarding-image-wrapper`}
        style={divStyle}
        ref={this.handleRef}
        key={`fixed-${JSON.stringify(image.srcSet)}`}
      >
        {/* Show a solid background color. */}
        {bgColor && (
          <div
            title={title}
            style={{
              backgroundColor: bgColor,
              width: width,
              opacity: !this.state.imgLoaded ? 1 : 0,
              height: height,
              ...(shouldFadeIn && delayHideStyle)
            }}
          />
        )}

        {/* Show the blurry base64 image. */}
        {image.base64 && (
          <Img
            src={image.base64}
            width={width}
            height={height}
            {...placeholderImageProps}
          />
        )}

        {/* Once the image is visible, start downloading the image. */}
        {this.state.isVisible && (
          <picture>
            {image.srcSetWebp && (
              <source
                type={`image/webp`}
                srcSet={image.srcSetWebp}
                sizes={image.sizes}
              />
            )}

            <Img
              alt={alt}
              title={title}
              width={width}
              height={height}
              sizes={image.sizes}
              src={image.src}
              crossOrigin={this.props.crossOrigin}
              srcSet={image.srcSet}
              style={imageStyle}
              ref={this.imageRef}
              onLoad={this.handleImageLoaded}
              onError={this.props.onError}
              itemProp={itemProp}
            />
          </picture>
        )}

        {/* Show the original image during server-side rendering if JavaScript is disabled. */}
        {this.state.hasNoScript && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: noscriptImg({
                alt,
                title,
                width,
                height,
                ...image
              })
            }}
          />
        )}
      </div>
    );
  }
}

export { Image };
