import { FC, useEffect } from "react";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";
import gettingStarted from "../../images/gettingStarted.svg";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { LoadingScreen } from "../LoadingScreen";
import { init, trackEvent } from '../../lib/AnalyticsService';
import { getExpectedStepNameByIndex, areAllStepsCompleted  } from "../../config/steps";
import { useAppStepTracker } from '../../context/AppStepTracker';
export const GettingStartedScreen: FC = () => {

    const navigate = useNavigate();

    const {data, isLoading} = useCurrentUser();

    const {lastStepCompleted} = useAppStepTracker();

    const userData = data?.data;

    const [searchParameters] = useSearchParams(window.location.search);

    const redirect = searchParameters.get('redirect');

    useEffect(() => {
        if (redirect){
            sessionStorage.setItem('onboarding_redirect_to', redirect);
            navigate('/');
        }
    },[])

    if(userData) {
      init(userData);
    }

    const goIntoStepper = () => {
      const eventName = isResumingOnboarding() ? 'Resume Account Activation' : 'Getting Started Screen';
      trackEvent(eventName, {
        'timestamp': new Date().toISOString(),
      });
      navigate(`${getExpectedStepNameByIndex((typeof lastStepCompleted !== 'number' && userData?.status != 'invited') ? 0 : lastStepCompleted)}`);
    }

    const isResumingOnboarding = () => {
        if(typeof lastStepCompleted === 'number') {
            // User completed at least one step, so they are resuming
            return true;
        }
        // Internal active users can resume process once they're active (password has been set)
        // SSO users are active by default, however they haven't started onboarding process yet
        return userData?.status === 'active' && !userData?.is_sso;
    }

    return ( isLoading ? <LoadingScreen /> : (
        areAllStepsCompleted(lastStepCompleted) ? (<Navigate to="/thank-you" />) : (
            <div className="flex flex-nowrap h-screen">
                <div className="md:basis-1/2 flex items-center">
                    <div className="mx-auto w-3/5">
                        <h1 className="text-gray-900 text-6xl leading-none font-extrabold">
                            Welcome to
                            <span className="block text-blue-600">AristaMD</span>
                        </h1>
                        <div className="text-gray-500 text-xl leading-7 font-normal py-5" >Before getting started we have to confirm your personal information and preferences. It should only take a minute. </div>
                        <div><button className="bg-blue-600 text-white rounded-sm px-6 py-2 hover:bg-blue-800" onClick={() => goIntoStepper()}>{isResumingOnboarding() ? 'Resume' : 'Get Started'}</button></div>
                    </div>
                </div>
                <div className="basis-1/2 md:block hidden my-auto">
                  <img src={gettingStarted} alt="Getting Started" className="w-9/12 h-9/12 mx-auto" loading="lazy" width={720} height={517}/>
                  <p className="md:mt-2 text-gray-500 text-xl leading-7 font-normal w-5/12 text-center mx-auto relative -top-16">Tap into our nationwide community of specialists who are dedicated to sharing knowledge and helping patients.</p>
                </div>
            </div>
        )
    ))
}