import {FC, ReactNode } from 'react'
import { NavigationSteps } from '../../components/NavigationSteps';
import { LoadingScreen } from '../../screens/LoadingScreen';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { getStepIndex, areAllStepsCompleted, STEPS, ValidStep, getExpectedStepNameByIndex, getExpectedStepIndex } from '../../config/steps';
import { Navigate } from 'react-router-dom';
import { useAppStepTracker } from '../../context/AppStepTracker';

type StepperLayoutProps ={
    children: ReactNode;
    stepName: ValidStep;
}

export const StepperLayout: FC<StepperLayoutProps> = (props) => {
    const {children, stepName} = props;

    const {lastStepCompleted} = useAppStepTracker();

    const stepIndex = getStepIndex(stepName);

    const getNextRoute = () => areAllStepsCompleted(lastStepCompleted) ? 'thank-you' : getExpectedStepNameByIndex(lastStepCompleted) 

    const {isLoading} = useCurrentUser();

    return (
        isLoading ? (<LoadingScreen />) : (
            stepIndex === getExpectedStepIndex(lastStepCompleted) ? (
                <div className="stepperLayout mx-auto px-4 sm:p-2">
                    <div className="max-w-full mb-8 mx-12 max-w-xl md:mx-auto mt-6">
                        <NavigationSteps
                        stepCount={STEPS.length}
                        currentStep={stepIndex}
                        />
                    </div>
                    <div>{children}</div>
                </div>
            ) : (<Navigate to={`/${getNextRoute()}`} />)
        )
    )
}