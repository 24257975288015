import { FC, ComponentType, ReactNode } from "react";
import StopIcon from '@heroicons/react/solid/MinusCircleIcon'
import CheckIcon from '@heroicons/react/solid/CheckIcon'
import range from 'lodash.range';


type IconName =
  | "stop"
  | "check";

const iconMap: Record<IconName, ComponentType<{ className?: string }>> = {
  stop: StopIcon,
  check: CheckIcon,
};

/**
 * NavigationSteps.
 */

interface NavigationStepsProps {
  currentStep: number;
  stepCount: number;
}

export const NavigationSteps: FC<NavigationStepsProps> = (props) => {
  const { currentStep, stepCount } = props;

  function getStepState(stepIndex: number) {
    if (currentStep === stepIndex) return "selected";
    if (stepIndex < currentStep) return "active";
    return "inactive";
  }

  return (
    <div className="navigationSteps flex items-center pb-8">
      {range(0, stepCount).map((idx: number) => (
        <NavigationStep
          key={`step-${idx + 1}`}
          state={getStepState(idx)}
          showSeparator={idx < stepCount - 1}
        />
      ))}
    </div>
  );
};

/**
 * NavigationStepBackground.
 */

export type NavigationStepState = "inactive" | "active" | "selected";

interface NavigationStepBackgroundProps {
  state: NavigationStepState;
  children: ReactNode;
}

const NavigationStepBackground: FC<NavigationStepBackgroundProps> = (props) => {
  const { state, children } = props;

  const cn = [
    "h-10 w-10 flex items-center justify-center rounded-full border transition-colors ease-in-out duration-300",
    state === "active" &&
    "bg-blue-500 text-white shadow-inner border-transparent",
    state === "selected" && "bg-white text-blue-600 border-blue-600",
    state === "inactive" && "bg-white border-gray-400",
  ]
    .filter(Boolean)
    .join(" ");

  return <div className={cn}>{children}</div>;
};

/**
 * NavigationStepLine.
 */

type NavigationStepLineProps = {
  state: NavigationStepState
}

const NavigationStepLine: FC<NavigationStepLineProps> = ({state}) => {
  return (
    <div className={`navigationStepLine ${state == 'active' ? 'border-blue-600' : 'border-gray-400'} border-t-2 flex-1`} />
  );
};

/**
 * Individual NavigationStep.
 */

interface NavigationStepProps {
  state: NavigationStepState;
  showSeparator: boolean;
}

const navigationStepClassNames: Record<NavigationStepState, string> = {
  active: "shadow-lg text-gray-800 cursor-pointer",
  selected: "text-gray-800 cursor-pointer",
  inactive: "text-gray-500",
};

const NavigationStep: FC<NavigationStepProps> = (props) => {
  const { state, showSeparator } = props;

  const getStepIcon = (state: string) => {
    switch (state) {
      case 'selected' :
        return <div className="w-4 h-4 rounded-full bg-blue-600" />
        break;
      case 'active' :
        return <CheckIcon className="w-6 h-6" />
      default:
        return;  
    }
  }

  return (
    <>
      <div
        className={`${navigationStepClassNames[state]} flex-0 h-10 w-10 relative rounded-full`}
      >
        <NavigationStepBackground state={state}>
          {getStepIcon(state)}
        </NavigationStepBackground>
      </div>
      {showSeparator && <NavigationStepLine state={state}/>}
    </>
  );
};
