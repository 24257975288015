import { 
    FC, 
    createContext, 
    ReactNode, 
    useMemo, 
    useContext,
    useState
} from "react";

export interface AppStepTrackerContext {
    lastStepCompleted: number | null,
    updateLastStepCompleted: (stepIndex: number) => void
}

const appStepTrackerContext = createContext<AppStepTrackerContext>(null!);

type AppStepTrackerProviderProps = {
    children: ReactNode;
}

export const SESSION_STORAGE_KEY = "last_step_completed";

export const AppStepTrackerProvider:FC<AppStepTrackerProviderProps> = ({children}) => {
    // Retrieves value from storage when context is loaded
    const valueFromStorage = sessionStorage.getItem(SESSION_STORAGE_KEY);

    // sets value from storage as integer if present
    const [lastStepCompleted, setLastStepCompleted] = useState<number|null>(
        valueFromStorage ? parseInt(valueFromStorage, 10) : null
    );

    // updates value on storage and locally
    const updateLastStepCompleted = (stepIndex: number) => {
        sessionStorage.setItem(SESSION_STORAGE_KEY, `${stepIndex}`);
        setLastStepCompleted(stepIndex);
    }
    
    const value = useMemo(() => ({lastStepCompleted, updateLastStepCompleted}), [lastStepCompleted])
    return (<appStepTrackerContext.Provider value={value}>{children}</appStepTrackerContext.Provider>)
}

export function useAppStepTracker() {
    const context = useContext(appStepTrackerContext);
    if (!context) {
        throw new Error(`appStepTrackerContext must be used within an AppStepTrackerProvider`);
    }
    return context;
}