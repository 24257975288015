import { FC } from "react";
import { AppRouter } from "./AppRouter";
import { AppQuery } from "./AppQuery";
import { AppSessionProvider } from "./AppSession";
import { AppStepTrackerProvider } from "./AppStepTracker";


export const AppProviders: FC = () => {
    return (
        <AppSessionProvider>
            <AppQuery>
                <AppStepTrackerProvider>
                  <AppRouter />
                </AppStepTrackerProvider>
            </AppQuery>
        </AppSessionProvider>
    )
}