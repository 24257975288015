import {
    FC,
    createContext,
    useContext,
    useCallback,
    useMemo,
    useEffect,
    ReactNode,
} from "react";
import { differenceInSeconds, addSeconds } from 'date-fns'
import Cookies from 'js-cookie';
import {
    AMD_AUTH_COOKIE_NAME,
    AMD_LOGIN_SERVICE_ORIGIN,
    AMD_AUTH_LOCK_COOKIE_NAME,
    AMD_COOKIE_EXPIRES_IN_SECONDS,
    AMD_AUTH_COOKIE_PATH,
    AMD_CLIENT_ID,
    AMD_CLIENT_SECRET,
    baseDomain
  } from "../config/constants";
import { updateAccessToken as axiosUpdateAccessToken } from "../lib/axios"; 
import refreshAccessToken from "../api-calls/refreshAccessToken";
import { logout as analyticsLogout } from '../lib/AnalyticsService';

let currentUserId: number | null;
let sessionStorageLocked = false;

interface CookieData {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    received_at: string;
    user_id: number;
}

export type RefreshTokenRequest = {
    client_id: string;
    client_secret: string;
    grant_type: string;
    refresh_token: string;
}

export type RefreshTokenHeaders = {
    'Content-Type': string,
    Accept: string,
    Authorization: string
}

export interface AppSessionContext {
    logout(): void;
    redirectLogin(): void;
    getCookie(): CookieData | null;
    isActive(): boolean;
}

const appSessionContext = createContext<AppSessionContext>(null!)

type AppSessionProviderProps = {
    children: ReactNode
}

export const AppSessionProvider: FC<AppSessionProviderProps> = ({children}) => {

    /**
     * Redirect to login page on auth service
     */
    const redirectLogin = useCallback(() => {
        clearSession(true);
        redirectToLoginService('/signin');
    }, []);

    /**
     * Redirect to logout page on auth service which handles logging out of all services
     */
    const logout = useCallback(() => {
        analyticsLogout();
        setRefreshLock()
        clearSession(true);
        redirectToLoginService('/logout')
    }, []);

    /**
     * Refresh the access token
     */
    const refreshToken = useCallback(() => {
        setRefreshLock();
        let cookie = getCookie();
        if (cookie) {
            const headers: RefreshTokenHeaders = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookie.access_token}`,
              };
              let request: RefreshTokenRequest = {
                client_id: AMD_CLIENT_ID,
                client_secret: AMD_CLIENT_SECRET,
                grant_type: "refresh_token",
                refresh_token: cookie.refresh_token
              };
              refreshAccessToken(headers, request).then(({ data }) => {
                removeRefreshLock();
                if (data.access_token)
                {
                    // Updates access token for Axios
                    axiosUpdateAccessToken(data.access_token)
                    const newCookie: CookieData = {
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        expires_in: data.expires_in,
                        received_at: (new Date()).toISOString(),
                        user_id: data.user_id
                    }
                    updateCookie(newCookie);
                    updateSessionStorageFromCookie(newCookie);
                }
              }).catch(() => {
                removeRefreshLock();
                logout();
              });
        }
    }, [logout])

    /**
     * Do some checks to validate the session, call itself again in 2 seconds
     * @returns {boolean}
     */
    const validate = useCallback(() => {
        let cookie = getCookie();

        // If the cookie has been deleted, redirect to the login page
        if (!cookie) {
            redirectLogin();
            return;
        }

        // If cookie user is different from the session user, reload the page
        if (cookie.user_id !== currentUserId) {
            window.location.reload();
            return false;
        }

        // Get the time left in the secs since the access token was emitted.
        const elapsedTime = differenceInSeconds(new Date(), new Date(cookie.received_at));

        // if for some reason the token already expired, don't try to refresh it and logout
        if (cookie.expires_in - elapsedTime <= 0 && !isRefreshLocked()) {
            logout();
            return false;
        }

        // If cookie expires in 2 mins or less, refresh the token
        if (cookie.expires_in - elapsedTime <= 120 && !isRefreshLocked()) {
            refreshToken();
            return false;
        }

        updateSessionStorageFromCookie(cookie);
        keepAlive();

        setTimeout(validate.bind(this), 2000);
        return true;
    }, [logout, refreshToken, redirectLogin, setTimeout])

    /**
     * Initialize session
     * @returns void
     */
    const init = useCallback(() => {
        clearStaleSession();
        let cookie = getCookie();
        if (!cookie) {
            redirectLogin();
            return;
        }
        currentUserId = cookie.user_id;
        validate();
    }, [validate, redirectLogin])

    // This will bse executed only if there is none user loaded
    useEffect(() => {
        if (!currentUserId) init();
    }, [init])

    const value = useMemo(() => ({ logout, getCookie, redirectLogin, isActive }), [logout, redirectLogin]);

    return (<appSessionContext.Provider value={value}>{children}</appSessionContext.Provider>)
}
export function useAppSessionContext() {
    const context = useContext(appSessionContext);
    if (!context) {
        throw new Error(`useAppSessionContext must be used within an AppSessionProvider`);
    }
    return context;
}

/**
 * Update auth cookie expiration date to keep it alive, since cookie is destroyed automatically after expiration.
 * When an AristaMD app is opened in a new tab, it will look for auth cookie. If the cookie doesn't exist, the
 * user will be required to log in again. Otherwise the session is kept alive and we can initialize a new
 * session from the auth cookie.
 */
function keepAlive() {
    if (!isRefreshLocked()) updateCookie();
}

/**
 * Store auth cookie.
 */
function setCookie(cookie: JSONObject): JSONObject {
    Cookies.set(
        AMD_AUTH_COOKIE_NAME,
        JSON.stringify(cookie),
        {
        expires: addSeconds(new Date(), AMD_COOKIE_EXPIRES_IN_SECONDS),
        path: AMD_AUTH_COOKIE_PATH,
        domain: baseDomain,
        secure: true,
        sameSite: 'None',
        }
    );
    return cookie;
}

/**
 * Get the auth cookie
 */
function getCookie(): CookieData | null {
    function rebuildCookieFromSessionStorage(): CookieData | null {
        const oauth = JSON.parse(sessionStorage.getItem('oauth') || 'null');

        if (oauth) {
            return {
                user_id: oauth.user_id,
                access_token: oauth.access_token,
                refresh_token: oauth.refresh_token,
                expires_in: oauth.expires_in,
                received_at: oauth.received_at
            }
        }
        return null;
    }

    let cookie = null;
    const cookieAsString = Cookies.get(AMD_AUTH_COOKIE_NAME);

    if (cookieAsString === '0') {
        // A value of '0' indicates the user logged out
        return cookie;
    } else if (cookieAsString === undefined) {
        // Cookie may have expired if validate timer didn't run or was delayed.
        // This can happen in IE while browsing for a file and in Safari for minimized or inactive tabs.
        // Attempt to rebuild the cookie from sessionStorage.
        try {
            cookie = rebuildCookieFromSessionStorage();
        } catch (e) {
            console.error(e);
        }
    } else if (!!cookieAsString) {
        try {
            cookie = JSON.parse(cookieAsString)
        } catch (e) {
            console.error(e);
        }
    }

    return cookie;
}

/**
 * Like setCookie but only adds data.
 */
function updateCookie(data?: CookieData | null) {
    return setCookie({ ...getCookie(), ...data });
}


/**
 * Redirect to auth service specific url with a set of provided parameters
 */
function redirectToLoginService(pathname: string, returnPath?: string) {
    const params = returnPath ? `?redirect=${encodeURIComponent(returnPath)}` : "";
    const href = AMD_LOGIN_SERVICE_ORIGIN + pathname + params;
    window.location.href = href;
}


/**
 * Set a temporary lock cookie to prevent multiple app instances (separate tabs) from trying
 * to refresh token at the same time.
 */
function setRefreshLock() {
    Cookies.set(
        AMD_AUTH_LOCK_COOKIE_NAME,
        'true',
        {
        expires: addSeconds(new Date(), 15),
        path: AMD_AUTH_COOKIE_PATH,
        domain: baseDomain
        }
    );
}

/**
 * Remove the token refresh lock cookie.
 */
function removeRefreshLock() {
    return Cookies.remove(AMD_AUTH_LOCK_COOKIE_NAME, { path: AMD_AUTH_COOKIE_PATH, domain: baseDomain });
}

/**
 * Returns true if token refresh lock cookie has been set.
 */
function isRefreshLocked(): boolean {
    return !!Cookies.get(AMD_AUTH_LOCK_COOKIE_NAME);
}

/**
 * Retrieves the cookie values from sessionStorage.
 */
function getOauthDataFromSession(): CookieData {
    return JSON.parse(sessionStorage.getItem('oauth') || 'null') || {};
}

function updateSessionStorageFromCookie(cookie: CookieData) {
    if (sessionStorageLocked) return;

    const oauth: CookieData = {
        ...getOauthDataFromSession(),
        ...cookie,
        received_at: cookie.received_at || (new Date()).toISOString()
    };
    sessionStorage.setItem('oauth', JSON.stringify(oauth));
}

function isSessionStale(): boolean {
    const cookieToken = getCookie()?.access_token;
    const sessionToken = getOauthDataFromSession().access_token
    return sessionToken !== cookieToken;
}

/**
 * Clear stale session from previous log in
 * This can happen when a user logs out while using multiple tabs
 */
function clearStaleSession() {
    if (isSessionStale()) sessionStorage.clear();
}

/**
 * Clear session data
 * @param lock Lock session storage to prevent changes
 */
function clearSession(lock: boolean) {
    if (lock) sessionStorageLocked = true;
    sessionStorage.clear();
}

/**
 * Validates if the session context is active (checks if validation timeout is set)
 */
function isActive(): boolean { return !!currentUserId } 

/**
 * Helper method for testing this context, it clears currentUserId value
 */
export const clearCurrentUserId = () => {
    if (typeof jest === 'undefined') {
        throw new Error('This method is only available for Jest testing');
    }
    currentUserId = null;
};