import { FC } from "react";
import { AvatarThumbnail } from "../../components/Avatar/Avatar";
import { ImageObject } from "../../components/Image/Image";
import { ImageWithIEPolyfill as Image } from "../../components/Image";
import quoteImage from "../../images/quote.png"
import defaultOrgLogo from "../../images/defaultOrgLogo.png"

export const defaultAvatar: ImageObject = {
    base64: "",
    src: "https://i.imgur.com/mCHMpLT.png?3",
    srcSet: "",
};

export type CiteObject = {
    avatarImage?: ImageObject,
    name: string,
    title: string
}

type CiteProps = {
    cite: CiteObject
}

const Cite: FC<CiteProps> = ({cite}) => {
    const {avatarImage, name, title} = cite;
    return (
        <div className="my-6">
            <div className="flex flex-row">
                <AvatarThumbnail
                    image={avatarImage || defaultAvatar}
                />
                <div className="text-base font-medium leading-6 ml-3">
                    <p>{name}</p>
                    <p className="text-blue-300">{title}</p>
                </div>
            </div>
        </div>
    )
}

type TestimonialQuoteProps = {
    organizationLogo?: ImageObject,
    cite: CiteObject
    quote: string,
    width?: number,
    height?: number,
}

export const TestimonialQuote: FC<TestimonialQuoteProps> = (props) => {
    const {organizationLogo, quote, cite, width=206, height=30} = props
    return(
        <div>
            <div className="mx-auto md:w-1/12 lg:w-1/2 2xl:w-5/12">
                <div className="flex flex-col">
                <Image image={organizationLogo || {src: defaultOrgLogo, srcSet: ""}} alt="Organization Logo" critical width={width} height={height}/>
                <Image image={{src: quoteImage, srcSet: ""}} alt="Quote" width={25} height={20} className="relative top-5 right-8" critical/>
                <span className="text-lg font-medium leading-7">
                    {quote} 
                </span>
                </div>
                <Cite cite={cite}/>
            </div>
        </div>
    )
}