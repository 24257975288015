import mixpanel from 'mixpanel-browser';
import get from 'lodash.get';
import { isProd } from '../config/constants.ts';

const WARN_MESSAGE = "Something went wrong. Our system couldn't connect with mixpanel.";
/**
 * Service that connect and send events to mixpanel
 * @return {Object} Analytics instance
 */
export const init = (user) => {
  try {
    const token = {
      dev: '095c1d3a72f075d5543e2408cf7aed52',
      prod: 'cf12ddfd4455ba0ef410959090d11b11',
    };
    mixpanel.init(isProd ? token.prod : token.dev);
    // register user and org information in all the events
    mixpanel.identify(user.id);
    const isInternal = !!(user.email && user.email.trim().toLowerCase().includes('@aristamd.com'));
    mixpanel.people.set({
      $email: user.email,
      username: user.username,
      source: 'Onboarding Service',
      user_type: (user.roles || []).map((role) => role.display_name),
      is_internal: isInternal,
      onboarding_complete: user.onboarding_complete,
    });
    mixpanel.set_group('organization_id', get(user, 'organization.id', null));
    mixpanel.set_group('organization_name', get(user, 'organization.display_name', null));
  } catch (e) {
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
    console.error(e);
    console.warn(WARN_MESSAGE);
  }
};
// call reset to clear data attributed to a user when that user logs out
export const logout = () => {
  try {
    if (mixpanel.persistence) {
      mixpanel.reset();
    }
  } catch (e) {
    console.error(e);
    console.warn(WARN_MESSAGE);
  }
};

// Track Events
// @param {string} eventName - The name of the event to be tracked.
// @param {object} properties - Additional properties associated with the event.
// @param {object|null} callback - An optional callback function to be executed after the event is tracked.
export const trackEvent = (eventName, properties, callback = null) => {
  try {
    if (mixpanel.config) {
      mixpanel.track(eventName, properties, callback);
    }
  } catch (e) {
    console.error(e);
    console.warn(WARN_MESSAGE);
  }
};

// When called for a particular event name,
// the next track call for that event name will include the elapsed time between
// the 'time_event' and 'track' calls. This value is stored as seconds in the '$duration' property.
// @param {string} eventName - The name of the event to measure the duration.
export const timeEvent = (eventName) => {
  try {
    mixpanel.time_event(eventName);
  } catch (e) {
    console.error(e);
    console.warn(WARN_MESSAGE);
  }
};
