import { FC, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useAppSessionContext } from "./AppSession";
import { updateAccessToken } from "../lib/axios";

type AppQueryProps = {
    children: ReactNode
}

export const AppQuery: FC<AppQueryProps> = ({children}) => {
    const {logout, getCookie} = useAppSessionContext();
    const authCookie = getCookie();
    if (authCookie) {
        updateAccessToken(authCookie.access_token);
    }
    const client = new QueryClient({
        defaultOptions: {
            queries: {
              // We don't need an aggressive re-fetch strategy.
              refetchInterval: false,
              refetchOnMount: false,
              refetchOnWindowFocus: false,
              retry: false,
              // If unauthorized, logout user
              onError: (err: any) => {
                if (err.response.status === 401){
                    logout();
                }
              }
            },
            mutations: {
                // If unauthorized, logout user
                onError: (err: any) => {
                    if (err.response.status === 401){
                        logout();
                    }
                  } 
            }
          }
    });
    return (
    <QueryClientProvider client={client}>
        {children}
        {(process.env.NODE_ENV !== 'test') && <ReactQueryDevtools/>}
    </QueryClientProvider>
   )
}