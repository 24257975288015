import { FC, memo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FAIconProps = { className?: string; icon: IconProp };

const FAIcon: FC<FAIconProps> = memo(({ className, icon }) => {
  return <FontAwesomeIcon className={className} icon={icon} />;
});

export { FAIcon };
