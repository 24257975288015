export type ValidStep = 'create-new-password' | 'confirm-information' | 'communication-preferences'

export const STEPS = [
    'create-new-password',
    'confirm-information',
    'communication-preferences',
];

const getStepNameByIndex = (index: number): string => {
    const step = STEPS[index];
    if (STEPS.indexOf(step) === -1)
    {
        throw new Error(`Step number ${index} is not a valid step`);
    }
    return step;
}

export const getStepIndex = (stepName: ValidStep) : number => STEPS.indexOf(stepName);

export const getExpectedStepIndex = (lastStepCompleted: any) => typeof lastStepCompleted === 'number' ? (lastStepCompleted +1) : 0;

export const getExpectedStepNameByIndex = (lastStepCompleted: any) => getStepNameByIndex(getExpectedStepIndex(lastStepCompleted));

export const areAllStepsCompleted = (lastStepCompleted: any) => typeof lastStepCompleted === 'number' && lastStepCompleted >= (STEPS.length -1)