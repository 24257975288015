const DOMAIN = window.location.hostname.split('.').slice(-2).join('.');
const PORT_NUMBER_REGEX = /:\d+/;

const url = window.location.host
  .replace(`.${DOMAIN}`, '')
  .replace('https://', '')
  .replace('www.', '')
  .replace(PORT_NUMBER_REGEX, '');

const hostParts = url.split('.').reverse();
const stage = hostParts[0] === 'onboarding' ? '' : `.${hostParts[0]}`;

export const isProd = stage === '';

export const baseDomain = `${stage}.${DOMAIN}`

export const ENV = isProd ? "PROD" : "DEV";

export const AMD_AUTH_COOKIE_NAME = `aristamd_auth${isProd ? "" : "_dev"}`;

export const AMD_LOGIN_SERVICE_ORIGIN = `https://login${baseDomain}`;

export const AMD_AUTH_COOKIE_PATH = '/';

export const AMD_COOKIE_EXPIRES_IN_SECONDS = 15;

export const AMD_AUTH_LOCK_COOKIE_NAME = AMD_AUTH_COOKIE_NAME.replace('auth', 'lock');

export const AMD_CLIENT_ID = '1';

export const AMD_CLIENT_SECRET =  'fultm56nuQZW2VSJYci9rTU9sikip1gnDQSiUmJl';