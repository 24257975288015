import { FC } from "react";
import { baseDomain } from "../../config/constants";
import { trackEvent } from '../../lib/AnalyticsService';

export const GetStartedSection:FC = () => {
    
    const goToApp = () => {
      trackEvent('Click Get Started');
      const originalRedirectUrl = sessionStorage.getItem('onboarding_redirect_to');
      window.location.href = originalRedirectUrl ?? `https://app${baseDomain}`; 
    }
    return(
        <div className="w-full text-center my-56">
            <p className="text-gray-900 text-4xl sm:text-5xl md:text-6xl leading-none font-extrabold">You are ready!</p>
            <p className="text-xl leading-7 font-normal text-gray-500 w-full sm:w-1/3 mx-auto px-3 sm:px-0 py-7">Next, we are going to take you into the eConsult platform so you can see how effective eConsults are for yourself!</p>
            <div>
                <button className="bg-blue-600 text-white rounded-sm px-6 py-2 hover:bg-blue-800" onClick={goToApp}>Get Started</button>
            </div>
        </div>
    )
}