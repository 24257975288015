import { FC, useState, useEffect } from "react";

type ReasonData = {
    specialty: string,
    reasons: string[]
}

type ReasonElementProps = {
    reason: ReasonData,
    specialtyIndex: number,
    groupIndex: number
}

const ReasonElement:FC<ReasonElementProps> = ({reason, specialtyIndex, groupIndex}) => {
    return (
        <div className="text-left" key={`group-${groupIndex}-specialty-${specialtyIndex}`}>
            <p 
                className="text-lg leading-7 font-medium gray-900"
                key={`group-${groupIndex}-specialty-${specialtyIndex}-name`}>{reason.specialty}
            </p>
            <ul className="list-disc list-inside" key={`group-${groupIndex}-specialty-${specialtyIndex}-reasonslist`}>
                {reason.reasons.map((reason: string, reasonIndex) => (
                    <li 
                        key={`group-${groupIndex}-specialty-${specialtyIndex}-reason-${reasonIndex}`}
                        className="text-base font-normal leading-6 text-gray-500"
                    >
                        {reason}
                    </li>
                ))}
            </ul>
        </div>
    )
}

type ReasonGroupProps = {
    reasonGroup: ReasonData[],
    groupIndex: number
}

const ReasonGroup:FC<ReasonGroupProps> = ({reasonGroup, groupIndex}) => {
    return (
        <div className="grid grid-cols-1 gap-2 lg:gap-0 lg:grid-cols-3" key={`group -${groupIndex}`}>
            {reasonGroup.map((reason: ReasonData, specialtyIndex: number) => (
                <ReasonElement 
                  reason={reason} 
                  specialtyIndex={specialtyIndex} 
                  groupIndex={groupIndex} 
                />
            ))}
        </div>
    )
} 

type EConsultReasonsProps = {
    reasons: ReasonData[]
}

export const EConsultReasons:FC<EConsultReasonsProps> = ({reasons}) => {

    const [reasonGroups, setReasonGroups] = useState<ReasonData[][]>([])

    useEffect(() => {
        if(reasons.length > 0)
        {
            const divideInSectionsOf = (size: number, elements: JSONArray) => {
                const groups = [];
                while (elements.length > 0)
                  groups.push(elements.splice(0, size));
                return groups
            }
            setReasonGroups(divideInSectionsOf(3, reasons));
        }
    }, [reasons])

    return (
        <div className="w-full bg-gray-50 text-center mb-8 sm:mb-0 sm:py-20">
            <p className="text-3xl leading-9 font-extrabold text-gray-900 py-6">Common Reasons for eConsults</p>
            <div className="mx-10 sm:mx-20">
                <div className={`grid gap-2 lg:gap-10 grid-rows-1 ${`lg:grid-rows-${reasonGroups.length}`}`}>
                    {reasonGroups.map((reasonGroup, groupIndex) => (
                        <ReasonGroup reasonGroup={reasonGroup} groupIndex={groupIndex}/>
                    ))}
                </div>
            </div>
        </div>
    )
}