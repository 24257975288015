import { FC } from "react";
import { StepperLayout } from "../../layouts/StepperLayout";
import { ConfirmationForm } from "./ConfirmationForm";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useNavigate } from "react-router-dom";
import { trackEvent } from '../../lib/AnalyticsService';
import { useAppStepTracker } from "../../context/AppStepTracker";
import { getStepIndex } from "../../config/steps";
import { AxiosResponse } from "axios";

export const ConfirmationFormScreen: FC = () => {
  const navigate  = useNavigate();
  const { updateLastStepCompleted } = useAppStepTracker()
  const { setQueryData } = useCurrentUser() || {};
  const onConfirmationFormSuccess = (data: AxiosResponse) => {
    trackEvent('Confirmation Form Screen', {
      'timestamp': new Date().toISOString(),
    });
    updateLastStepCompleted(getStepIndex('confirm-information'))
    setQueryData(data);
    navigate('/communication-preferences');
  }
  return(
    <StepperLayout stepName="confirm-information">
      <div className="text-center sm:text-left mx-8 lg:mx-20">
        <h1 className="text-gray-900 text-4xl sm:text-5xl md:text-6xl leading-none font-extrabold">Confirm your information</h1>
      </div>
      <div className="flex flex-col justify-center items-center lg:flex-row">
        {/* Left Side */}
        <div className="w-full lg:w-1/2 ml-0 md:ml-20">
          <div className="text-center sm:text-left text-gray-500 text-xl leading-7 font-normal py-5 w-full mb-5">
            <p className="text-lg">Please review and make sure we have the correct information for your account.</p>
          </div>
          <ConfirmationForm onConfirmationFormSuccess={onConfirmationFormSuccess}/>
        </div>

        {/* Right Side */}
        <div className="lg:w-1/2 hidden lg:flex flex-col items-center justify-center">
          <div className="w-5/12 h-auto">
            <h1 className="text-8xl text-center font-bold text-blue-500">92%</h1>
          </div>
          <div className="text-gray-500 text-xl leading-7 font-normal py-5 w-3/5">
            <p className="text-lg text-center mt-4">PCPs report 92% of eConsults significantly impact the patient care plan.</p>
          </div>
        </div>
      </div>
    </StepperLayout>
  )
}
