import { FC, useEffect } from "react";
import { StepperLayout } from "../../layouts/StepperLayout";
import { PasswordForm } from "./PasswordForm";
import { TestimonialQuote } from "./TestimonialQuote";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useNavigate, Navigate } from "react-router-dom";
import emcaraLogo from "../../images/emcaraLogo.png"
import christopherDodd from "../../images/christopherDodd.png"
import { trackEvent } from '../../lib/AnalyticsService';
import { useAppStepTracker } from "../../context/AppStepTracker";
import { getStepIndex } from "../../config/steps";
import { AxiosResponse } from "axios";

export const CreatePasswordScreen: FC = () => {
    const navigate  = useNavigate();
    const {updateLastStepCompleted, lastStepCompleted} = useAppStepTracker();
    const {data, setQueryData, isLoading } = useCurrentUser();
    const userData = data?.data;
    const onPasswordChangeSuccess = (data: AxiosResponse) => {
        trackEvent('Create Password Screen', {
            'timestamp': new Date().toISOString(),
        });
        updateLastStepCompleted(getStepIndex('create-new-password'));
        setQueryData(data);
        navigate('/confirm-information');
    }
    // If step tracker has not been initialized yet and user doesn't need to change its password, let's update the last step completed
    useEffect(() => {
        if(!isLoading && typeof lastStepCompleted != 'number' && userData?.status !== 'invited')
        {
            updateLastStepCompleted(getStepIndex('create-new-password'));
        }
    }, [isLoading])
    return(
        <StepperLayout stepName="create-new-password">
            {/* If user status is invited it must change its password */}
            { isLoading ? '' : userData?.status === 'invited' ? (
              <>
                <div className="text-center sm:text-left mx-8 lg:mx-20">
                  <h1 className="text-gray-900 text-4xl sm:text-5xl md:text-6xl leading-none font-extrabold">Create New Password</h1>
                    <div className="text-gray-500 text-xl leading-7 font-normal py-5 w-full">
                        <p className="text-lg">Set your new password so you can login and access AristaMD.</p>
                    </div>
                </div>
                <div className="px-2 sm:px-0 flex flex-col xl:justify-center lg:flex-row">
                    <div className="w-full lg:w-1/2 ml-0 md:ml-10 lg:ml-20">
                        <PasswordForm userId={userData.id} onPasswordChangeSuccess={onPasswordChangeSuccess}/>
                    </div>

                    <div className="lg:w-1/2 hidden xl:block xl:flex flex-col justify-center">
                        <TestimonialQuote
                        organizationLogo={{src: emcaraLogo, srcSet: ''}}
                        cite={{name: "Christopher Dodd, MD", title: "Chief Medical Officer, Emcara Health", avatarImage: {src: christopherDodd, srcSet:''}}}
                        quote="The immediate and tangible impact of eConsults for Emcara Health is the ablility to avoid unnecessary referrals, while the long-term benefits include enhancing patient experience and the quality of care."
                        />
                    </div>

                </div>
              </>
            ) : <Navigate to="/confirm-information"/>}
        </StepperLayout>
    )
}
