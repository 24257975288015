import { Navigate, RouteObject } from "react-router-dom";
import { GettingStartedScreen } from "./screens/GettingStartedScreen";
import { CreatePasswordScreen } from "./screens/CreatePasswordScreen";
import { ConfirmationFormScreen } from "./screens/ConfirmationFormScreen";
import { PreferencesScreen } from "./screens/PreferencesScreen";
import { ThankYouScreen } from "./screens/ThankYouScreen";

export const routes: RouteObject[] = [
    {
        path: "/",
        element: <GettingStartedScreen />
    },
    {
        path: "/create-new-password",
        element: <CreatePasswordScreen />
    },
    {
        path: "/confirm-information",
        element: <ConfirmationFormScreen />
    },
    {
        path: "/communication-preferences",
        element: <PreferencesScreen />
    },
    {
        path: "/thank-you",
        element: <ThankYouScreen />
    },
    {
        path: "*",
        element: <Navigate to="/"/>
    }
];