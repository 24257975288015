import { FC } from "react";
import { StepperLayout } from "../../layouts/StepperLayout";
import { PreferencesForm } from "./PreferencesForm";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useNavigate } from "react-router-dom";
import truecareLogo from "../../images/truecareLogo.png";
import { TestimonialQuote } from "../CreatePasswordScreen/TestimonialQuote";
import { trackEvent } from '../../lib/AnalyticsService';
import { useAppStepTracker } from "../../context/AppStepTracker";
import { getStepIndex } from "../../config/steps";
import { AxiosResponse } from "axios";

export const PreferencesScreen: FC = () => {
    const navigate  = useNavigate();
    const { setQueryData } = useCurrentUser() || {};
    const {updateLastStepCompleted} = useAppStepTracker();
    const onPreferencesFormSuccess = (data: AxiosResponse) => {
      trackEvent('Preferences Screen', {
        'timestamp': new Date().toISOString(),
      });
      updateLastStepCompleted(getStepIndex('communication-preferences'));
      setQueryData(data);
      navigate('/thank-you');
    }

    return(
        <StepperLayout stepName="communication-preferences">
          <div className="text-center sm:text-left mx-8 lg:mx-20">
            <h1 className="text-gray-900 text-4xl md:text-5xl lg:text-6xl leading-none font-extrabold">Communication Preferences</h1>
          </div>
          <div className="flex flex-col justify-center items-center lg:flex-row">
            {/* Left Side */}
            <div className="w-full lg:w-1/2 ml-0 md:ml-20">
              <div className="text-center sm:text-left text-gray-500 text-xl leading-7 font-normal py-5 w-full mb-5">
                <p className="text-lg mx-6 sm:mx-8 lg:mx-0">What type of emails would you like to receive?</p>
              </div>
              <PreferencesForm onPreferencesFormSuccess={onPreferencesFormSuccess}/>
            </div>

            {/* Right Side */}
            <div className="lg:w-1/2 hidden lg:flex flex-col items-center justify-center">
              <TestimonialQuote
                organizationLogo={{src: truecareLogo, srcSet: ''}}
                cite={{name: "Jennifer Young, MS, FNP-BC", title: "TrueCare"}}
                quote="I love to learn what common things can be done to help the patient prior to sending them far away to see a specialist."
                width={220}
                height={48}
              />
            </div>
          </div>
        </StepperLayout>
    )
}