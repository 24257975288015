import { FC, useCallback, useState } from "react";
import { Formik, FormikHelpers } from 'formik';
import { Button } from "../../components/Button/Button";
import * as Yup from 'yup';
import { SingleCheckboxInput } from "../../components/formik/SingleCheckboxField/SingleCheckboxField";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import updateUserById from "../../api-calls/updateUserById";
import { getStepIndex } from "../../config/steps";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { useAppStepTracker } from "../../context/AppStepTracker";
import { AxiosResponse } from "axios";
import { Spinner } from "../../components/Spinner/Spinner";

interface PreferencesFormValues {
    insightsReporting?: boolean;
    eConsultsUseCases?: boolean;
    newsAndUpdates?: boolean;
}

const validationSchema: Yup.ObjectSchema<PreferencesFormValues> = Yup.object({
    insightsReporting: Yup.boolean(),
    eConsultsUseCases: Yup.boolean(),
    newsAndUpdates: Yup.boolean(),
})

type PreferencesFormProps = {
    onPreferencesFormSuccess: (data: AxiosResponse) => void,
}

export const PreferencesForm: FC<PreferencesFormProps> = (props) => {
    const { onPreferencesFormSuccess } = props
    const { data } = useCurrentUser();
    const navigate = useNavigate();
    const { updateLastStepCompleted } = useAppStepTracker();
    const [clickedButton, setClickedButton] = useState<string | null>(null);

    const userData = data?.data;
    const updateUserDataMutation = useMutation({mutationFn: (preferencesFormData: object) => updateUserById(userData?.id, preferencesFormData)});

    const onSubmit = useCallback(
      async (values: PreferencesFormValues, formikActions: FormikHelpers<PreferencesFormValues>) => {
        const { setStatus, setSubmitting } = formikActions;
        setClickedButton('next');

        updateUserDataMutation.mutate(
          {
            ...userData,
            communications: {
              insights_reporting: values.insightsReporting,
              econsults_use_cases: values.eConsultsUseCases,
              news_and_updates: values.newsAndUpdates,
            }
          },
          {
            onSuccess: onPreferencesFormSuccess,
            onError: (error: any) => {
              setStatus({
                errors: [
                  {
                    key: "",
                    message: `Something went wrong. ${error.response.data.message}`,
                  },
                ],
              });
            },
            onSettled: () => {
              setSubmitting(false);
            },
          }
        );
      }, [userData]);

    const goBack = async() => {
      setClickedButton('back');
      updateLastStepCompleted(getStepIndex('create-new-password'));
      navigate("/create-new-password");
    }

    return (
      <div className="_preferencesForm px-2 w-full sm:w-10/12">
        <Formik<PreferencesFormValues>
        initialValues={{
            insightsReporting: userData?.communications?.insights_reporting || true,
            eConsultsUseCases: userData?.communications?.econsults_use_cases || true,
            newsAndUpdates: userData?.communications?.news_and_updates || true,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        >
          {({
          handleSubmit,
          isSubmitting,
          }) => (
          <form onSubmit={handleSubmit}>
            <SingleCheckboxInput
              name="insightsReporting"
              checkboxLabel="Insights & Reporting"
              checkboxDescription="Monthly emails with detailed information on your personal impact with eConsults."
            />
            <SingleCheckboxInput
              name="eConsultsUseCases"
              checkboxLabel="Use Cases for eConsults"
              checkboxDescription="Monthly emails that share interesting and complex use cases for eConsults."
            />
            <SingleCheckboxInput
              name="newsAndUpdates"
              checkboxLabel="News & Updates"
              checkboxDescription="Stay in the loop with important product updates and news about eConsults."
            />
            <div className="mt-8 block w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  disabled={updateUserDataMutation?.isLoading}
                  color="blue"
                  className="ml-5 w-1/3 sm:w-1/5 float-right"
                >
                  Next
                  {clickedButton === 'next' && updateUserDataMutation?.isLoading &&
                    <Spinner className="ml-1" size={10} color="light-blue" borderSize={2}/>
                  }
                </Button>
                 <Button
                   type="button"
                   disabled={updateUserDataMutation?.isLoading}
                   className="w-1/3 sm:w-1/4 xl:w-1/5 float-right"
                   onClick={goBack}
                 >
                  Go back
                 { (clickedButton === 'back' && updateUserDataMutation?.isLoading) ?
                   <Spinner className="ml-1" size={10} color="light-blue" borderSize={2}/>
                   : null
                 }
                </Button>

            </div>
          </form>
        )}
        </Formik>
      </div>
    )
}