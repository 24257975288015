import React, { ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import { AppColor } from '../../styles/colorStyles';

type ButtonType = 'primary' | 'secondary' | 'tertiary';
type ButtonSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

const baseClassNames =
  'inline-flex items-center justify-center focus:outline-none transition ease-in-out duration-150';

function classNamesForType(btnType: ButtonType, btnColor: AppColor): string {
  switch (btnType) {
    case 'primary':
      return 'border ' + primaryClassNamesForColor(btnColor);
    case 'secondary':
      return (
        'border border-transparent ' + secondaryClassNamesForColor(btnColor)
      );
    case 'tertiary':
      return tertiaryClassNamesForColor(btnColor);
  }
}

function classNamesForSize(btnSize: ButtonSize): string {
  switch (btnSize) {
    case 'xs':
      return 'px-2.5 py-1.5 text-xs leading-4 font-medium rounded';
    case 'sm':
      return 'px-3 py-2 text-sm leading-4 font-medium rounded-md';
    case 'base':
      return 'px-2 py-2 text-sm leading-5 font-medium rounded-md';
    case 'lg':
      return 'px-2 py-2 text-base leading-6 font-medium rounded-md';
    case 'xl':
      return 'px-6 py-3 text-base leading-6 font-medium rounded-md';
  }
}

function primaryClassNamesForColor(btnColor: AppColor): string {
  switch (btnColor) {
    case 'red':
      return 'border-transparent text-white bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red active:bg-red-700';
    case 'orange':
      return 'border-transparent text-white bg-orange-600 hover:bg-orange-500 focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700';
    case 'yellow':
      return 'border-transparent text-white bg-yellow-600 hover:bg-yellow-500 focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700';
    case 'green':
      return 'border-transparent text-white bg-green-600 hover:bg-green-500 focus:border-green-700 focus:shadow-outline-green active:bg-green-700';
    case 'blue':
      return 'border-transparent text-white bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700';
    case 'indigo':
      return 'border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700';
    case 'purple':
      return 'border-transparent text-white bg-purple-600 hover:bg-purple-500 focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700';
    case 'pink':
      return 'border-transparent text-white bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:shadow-outline-pink active:bg-pink-700';
    case 'teal':
      return 'border-transparent text-white bg-teal-600 hover:bg-teal-500 focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700';
    case 'gray':
      return 'border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50';
  }
}

function secondaryClassNamesForColor(btnColor: AppColor): string {
  switch (btnColor) {
    case 'red':
      return 'text-red-700 bg-red-100 hover:bg-red-50 focus:border-red-300 focus:shadow-outline-red active:bg-red-200';
    case 'orange':
      return 'text-orange-700 bg-orange-100 hover:bg-orange-50 focus:border-orange-300 focus:shadow-outline-orange active:bg-orange-200';
    case 'yellow':
      return 'text-yellow-700 bg-yellow-100 hover:bg-yellow-50 focus:border-yellow-300 focus:shadow-outline-yellow active:bg-yellow-200';
    case 'green':
      return 'text-green-700 bg-green-100 hover:bg-green-50 focus:border-green-300 focus:shadow-outline-green active:bg-green-200';
    case 'teal':
      return 'text-teal-700 bg-teal-100 hover:bg-teal-50 focus:border-teal-300 focus:shadow-outline-teal active:bg-teal-200';
    case 'blue':
      return 'text-blue-700 bg-blue-100 hover:bg-blue-50 focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-200';
    case 'indigo':
      return 'text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200';
    case 'purple':
      return 'text-purple-700 bg-purple-100 hover:bg-purple-50 focus:border-purple-300 focus:shadow-outline-purple active:bg-purple-200';
    case 'pink':
      return 'text-pink-700 bg-pink-100 hover:bg-pink-50 focus:border-pink-300 focus:shadow-outline-pink active:bg-pink-200';
    case 'gray':
      return 'text-gray-700 bg-gray-100 hover:bg-gray-50 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-200';
  }
}

function tertiaryClassNamesForColor(btnColor: AppColor): string {
  switch (btnColor) {
    case 'red':
      return 'border-red-300 text-red-700 bg-white hover:text-red-500 hover:bg-red-50 focus:border-red-300 focus:shadow-outline-red active:text-red-800 active:bg-red-50';
    case 'orange':
      return 'border-orange-300 text-orange-700 bg-white hover:text-orange-500 hover:bg-orange-50 focus:border-orange-300 focus:shadow-outline-orange active:text-orange-800 active:bg-orange-50';
    case 'yellow':
      return 'border-yellow-300 text-yellow-700 bg-white hover:text-yellow-500 hover:bg-yellow-50 focus:border-yellow-300 focus:shadow-outline-yellow active:text-yellow-800 active:bg-yellow-50';
    case 'green':
      return 'border-green-300 text-green-700 bg-white hover:text-green-500 hover:bg-green-50 focus:border-green-300 focus:shadow-outline-green active:text-green-800 active:bg-green-50';
    case 'teal':
      return 'border-teal-300 text-teal-700 bg-white hover:text-teal-500 hover:bg-teal-50 focus:border-teal-300 focus:shadow-outline-teal active:text-teal-800 active:bg-teal-50';
    case 'blue':
      return 'border-blue-300 text-blue-700 bg-white hover:text-blue-500 hover:bg-blue-50 focus:border-blue-300 focus:shadow-outline-blue active:text-blue-800 active:bg-blue-50';
    case 'indigo':
      return 'border-indigo-300 text-indigo-700 bg-white hover:text-indigo-500 hover:bg-indigo-50 focus:border-indigo-300 focus:shadow-outline-indigo active:text-indigo-800 active:bg-indigo-50';
    case 'purple':
      return 'border-purple-300 text-purple-700 bg-white hover:text-purple-500 hover:bg-purple-50 focus:border-purple-300 focus:shadow-outline-purple active:text-purple-800 active:bg-purple-50';
    case 'pink':
      return 'border-pink-300 text-pink-700 bg-white hover:text-pink-500 hover:bg-pink-50 focus:border-pink-300 focus:shadow-outline-pink active:text-pink-800 active:bg-pink-50';
    case 'gray':
      return 'border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50';
  }
}

export interface ButtonProps {
  /** Button style prop */
  color?: AppColor;
  /** Button style prop */
  kind?: ButtonType;
  /** Button style prop */
  size?: ButtonSize;
  /** Button style prop */
  className?: string;
  /** Optional icon. */
  icon?: ReactNode;
  /** Icon only button (tweaks styling) */
  iconOnly?: boolean;
  /** Whether the button is disabled. */
  disabled?: boolean;
  /** Whether the button is loading (shows spinner). */
  isLoading?: boolean;
  /** The type of the button */
  type?: 'button' | 'submit';
  /** The callback to call when the button is clicked */
  onClick?(event: React.MouseEvent): void;
  children?: ReactNode;
  style?: React.CSSProperties;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      type = 'button',
      color = 'gray',
      kind = 'primary',
      size = 'base',
      className,
      onClick,
      children,
      disabled,
      isLoading,
      style,
    } = props;

    const cn = cx(
      baseClassNames,
      classNamesForSize(size),
      classNamesForType(kind, color),
      className,
      { 'opacity-50 cursor-not-allowed': disabled }
    );
    return (
      <button
        ref={ref}
        type={type}
        className={cn}
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        {isLoading ? (
          <svg
            className="animate-spin"
            style={{
              height: '1.3em',
              width: '1.3em',
              marginLeft: '-0.25em',
              marginRight: '0.75em',
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : null}
        {children}
      </button>
    );
  }
);
