import { useAppSessionContext } from "../context/AppSession";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import fetchUserById from "../api-calls/fetchUserById";
import { AxiosResponse } from "axios";

export const useCurrentUser = () => {
    const {getCookie, redirectLogin} = useAppSessionContext();
    const queryClient = useQueryClient();
    const cookie = getCookie();
    if (!cookie) {
        redirectLogin();
    }
    // Updates query data with put response
    // https://tanstack.com/query/v4/docs/react/guides/updates-from-mutation-responses
    const setQueryData = (data: AxiosResponse) => {
        queryClient.setQueryData(['fetchUser', userId], data)
    };
    const userId = cookie?.user_id;
    return {...useQuery({
        queryKey: ['fetchUser', userId],
        queryFn: async () => {
            const data = await fetchUserById(userId)
            return data
          },
    }), setQueryData};
}