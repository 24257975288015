import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AppProviders } from './context';
import { configure as axiosConfigure } from './lib/axios';

axiosConfigure({baseURL: '/api'})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProviders />
  </React.StrictMode>
);
